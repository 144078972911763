import ENV from '../environment';

const S3_BUCKET = ENV.s3_bucket;
const REGION = ENV.s3_region;
const ACCESS_KEY = ENV.s3_access_key;

const config = {
  bucketName: S3_BUCKET,
  dirName: 'nchat/media',
  region: REGION,
  accessKeyId: ACCESS_KEY,
}


export {
  config
};
