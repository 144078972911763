function ENV() {
  let ENV = {
    apiUrl: '',
    apiCF:'',
    apiPath: 'patient_portal',
    patientScope: '',
    s3_bucket: '',
    s3_region: '',
    s3_access_key: '',
    fb_api_key: '',
    fb_auth_domain: '',
    fb_db_url: ''
  };


  if(process.env.REACT_APP_STAGE === 'development') {
    ENV.apiUrl = 'http://localhost:5000/api/v1';
    ENV.apiCF = 'https://us-central1-chat-7d316.cloudfunctions.net/staging';
    ENV.s3_bucket  ='nimbox-dev';
    ENV.s3_region ='us-east-1';
    ENV.s3_access_key ='AKIAZBWON4N4LTTGCIFN';
    ENV.fb_api_key = 'AIzaSyDs01fNI0HlVgSitMogzN5oTVR3yTa2G2s';
    ENV.fb_auth_domain = 'chat-7d316.firebaseapp.com';
    ENV.fb_db_url = 'https://chat-7d316-default-rtdb.firebaseio.com/';
  }

  if(process.env.REACT_APP_STAGE === 'staging') {
    ENV.apiUrl = 'https://nimbox-api-staging.herokuapp.com/api/v1';
    ENV.apiCF = 'https://us-central1-chat-7d316.cloudfunctions.net/staging';
    ENV.s3_bucket  ='nimbox-staging';
    ENV.s3_region ='us-east-1';
    ENV.s3_access_key ='AKIAZBWON4N4OOUMXLET';
    ENV.fb_api_key = 'AIzaSyDs01fNI0HlVgSitMogzN5oTVR3yTa2G2s';
    ENV.fb_auth_domain = 'chat-7d316.firebaseapp.com';
    ENV.fb_db_url = 'https://chat-7d316-default-rtdb.firebaseio.com/';
  }

  if(process.env.REACT_APP_STAGE === 'production') {
    ENV.apiUrl = 'https://nimbox-api-production.herokuapp.com/api/v1';
    ENV.apiCF = 'https://us-central1-chat-7d316.cloudfunctions.net/production';
    ENV.s3_bucket  ='nimbox-production';
    ENV.s3_region ='us-east-1';
    ENV.s3_access_key ='AKIAZBWON4N4OQSXVTJT';
    ENV.fb_api_key = 'AIzaSyDs01fNI0HlVgSitMogzN5oTVR3yTa2G2s';
    ENV.fb_auth_domain = 'chat-7d316.firebaseapp.com';
    ENV.fb_db_url = 'https://nchat-production.firebaseio.com/';
  }

  ENV.patientScope = `${ENV.apiUrl}/${ENV.apiPath}`;

  return ENV;
};

export default ENV();